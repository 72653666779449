import { template as template_f6c2d709be954bdfa5ab13dab9c8c226 } from "@ember/template-compiler";
const FKText = template_f6c2d709be954bdfa5ab13dab9c8c226(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
