import { template as template_67e0d3230f3144088bd3c3af5ec03fcb } from "@ember/template-compiler";
const FKLabel = template_67e0d3230f3144088bd3c3af5ec03fcb(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
